import getContrastText from '@tgrx/getcontrasttext';


/* As RGBa codes */

export const theme = {
	hex: {
		primary: {
			main: "#DB514A",	/* Main Primary color */
			lighter: "#FFA8A3",
			light: "#FC7D77",
			dark: "#C02E26",
			darker: "#98160F",
			lightest: "#ffdbd9",
			darkest: "#330705",
		},
		secondary: {
			main: "#2E7E85",	/* Main Secondary color (1) */
			lighter: "#7BBAC0",
			light: "#499299",
			dark: "#186D75",
			darker: "#0A565D",
			lightest: "#ccfbff",
			darkest: "#042124",

},
		tertiary: {
			main: "#9BCB45",	/* Main Secondary color (2) */
			lighter: "#D4F39C",
			light: "#BEEA6E",
			dark: "#7FB224",
			darker: "#608E0E",
			lightest: "#eaffc4",
			darkest: "#1a2604",

}
	},
	rgba: {
		primary: {
			main: (op=1) => 		`rgba(219, 81, 74, ${op})`,	// Main Primary color */
			lighter: (op=1) =>  `rgba(255,168,163, ${op})`,
			light: (op=1) =>  	`rgba(252,125,119, ${op})`,
			dark: (op=1) =>  		`rgba(192, 46, 38, ${op})`,
			darker: (op=1) =>  	`rgba(152, 22, 15, ${op})`,
			lightest: (op=1) => `rgba(255,219,217, ${op})`,
			darkest: (op=1) =>  `rgba( 51,  7,  5, ${op})`
		},
		secondary: {
			main: (op=1) =>  			`rgba( 46,126,133, ${op})`,	// Main Secondary color (1) */
			lighter: (op=1) =>  	`rgba(123,186,192, ${op})`,
			light: (op=1) =>  		`rgba( 73,146,153, ${op})`,
			dark: (op=1) =>  			`rgba( 24,109,117, ${op})`,
			darker: (op=1) =>  		`rgba( 10, 86, 93, ${op})`,
			lightest: (op=1) =>  	`rgba(204,251,255, ${op})`,
			darkest: (op=1) =>  	`rgba(  4, 33, 36, ${op})`
		},
		tertiary: {
			main: (op=1) =>  			`rgba(155,203, 69, ${op})`,	// Main Secondary color (2) */
			lighter: (op=1) =>  	`rgba(212,243,156, ${op})`,
			light: (op=1) =>  		`rgba(190,234,110, ${op})`,
			dark: (op=1) =>  			`rgba(127,178, 36, ${op})`,
			darker: (op=1) =>  	 	`rgba( 96,142, 14, ${op})`,
			lightest: (op=1) =>  	`rgba(234,255,196, ${op})`,
			darkest: (op=1) =>  	`rgba( 26, 38,  4, ${op})`
		}
	}
};

Object.values(theme.hex).forEach(c => {
	c.contrastText = getContrastText.default({
		background: c.main,
		light: {text: {primary: c.light}},
		dark: {text: {primary: c.dark}},
		contrastThreshold: 3
	});
});

Object.values(theme.rgba).forEach(c => {
	c.contrastText = getContrastText.default({
		background: c.main(),
		light: {text: {primary: c.light()}},
		dark: {text: {primary: c.dark()}},
		contrastThreshold: 3
	});
});
