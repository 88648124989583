import React, {useCallback, useContext, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {theme} from '../../core/theme';
import LogoText from "../common/LogoText";
import {useToggleState} from "../../core/hooks";
import {fullsizeBeforeBlock} from "../../core/styles";
import ContactForm from './ContactForm';

import {getDebug} from "../../core/polyfills";

const debug = getDebug(__filename);

const colours = [
	'#9BC1BC',
	'#F4F1BB',
	'#ED6A5A',
	'#E6EBE0',
	'#5CA4A9',
];

export default function Intro() {
	const [showSignup, toggleSignup] = useToggleState(false);

	return (
		<Wrapper>
			<List>
				<Item>
					<CheckBox />
				</Item>
				<Item>
					<CheckBox checked />
					<Logo />
				</Item>
				<Item>
					<CheckBox checked />
					<h2>The todo list with your wellbeing at heart</h2>
				</Item>
				<Item onClick={toggleSignup}>
					<CheckBox focus checked={showSignup} />
					<SignupText>Sign up for preview testing!</SignupText>
				</Item>
				<Item>
					<CheckBox />
				</Item>
			</List>
			<Form {...{showSignup, toggleSignup}} />
		</Wrapper>
	);
}

const List = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	
	&>div:first-child {
		background: ${colours[0]}; 
	}
	&>div:nth-child(2) {
		background: ${colours[1]}; 
	}
	&>div:nth-child(3) {
		background: ${colours[2]};
	}
	&>div:nth-child(4) {
		background: ${colours[3]};
	}
	&>div:nth-child(5) {
		background: ${colours[4]};
	}
`;


const Form = styled(({toggleSignup, showSignup, ...props}) => {
	return (
		<React.Fragment>
			<Dialog open={showSignup} onClose={toggleSignup} aria-labelledby="form-dialog-title" {...props}>
				<DialogContent>
					<p>Hi!</p>
					<p>Just so you know, we will not use your details for any other purpose than to invite you to participate in tests.</p>
					<ContactForm onAction={toggleSignup} />
				</DialogContent>
			</Dialog>
			<Hidden>
				<ContactForm />
			</Hidden>
		</React.Fragment>
	);
})``;


const MouseOverContext = React.createContext();

const Item = styled(({children, ...props}) => {
	const [mouseover, setMouseOver] = useState();

	return (
		<MouseOverContext.Provider value={mouseover}>
			<div {...props}>
				<div className='itemInner' onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver()}>
					{children}
				</div>
			</div>
		</MouseOverContext.Provider>
	);
})`
	position: relative;
	flex: 0 0 20%;
	display: flex;
	justify-content: space-around;
	
	${({onClick}) => (onClick && `cursor: pointer;`)}
	
	.itemInner {
		width: 100%;		
		display: grid;
		grid-template-columns: 6vmin 1fr;
		align-items: center;
		grid-gap: 3vmin;

		
		@media (min-width: 1601px) {
			max-width: 52vmin;
			grid-grap: 10vmin;
		}
		@media (max-width: 1600px) {
			max-width: 52vmin;
			grid-grap: 10vmin;
		}
		
		
		@media (max-width: 420px) and (orientation: portrait) {
			max-width: calc(100% - 4rem);
			grid-gap: 2rem;
			grid-template-columns: 2rem 1fr;
		}
	}
`;


const steadyHeartbeat = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0.3;
  }
  10% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.3;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.3;
  }
`;

const onePulseHeartbeat = keyframes`
  0% {
    transform: scale(0.8);
   	opacity: 0.8;
  }
  5% {
    transform: scale(1.1);
    opacity: 1;         
  }
  10% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  15% {
    transform: scale(1.3);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
`;

const settleHeartbeat = keyframes`
	15% {
		transform: scale(1.2);		
	}
	
  100% {
    transform: scale(1);
  }
`;


const SignupText = styled(({children, className, ...props}) => {
	const mouseover = useContext(MouseOverContext);
	return (
		<h3 {...props} className={classnames(mouseover && 'mouseover', className)}>{children}</h3>
	);
})`
	&.mouseover {		
		color: ${theme.hex.primary.darker};
	}
`;

const CheckBox = styled(({className, checked, focus, ...props}) => {
	const mouseover = useContext(MouseOverContext);
	const [_checked, toggle] = useToggleState(checked);
	const [_focus, setFocus] = useState(focus);

	const onClick = useCallback(() => {
		toggle();
		setFocus();
	}, [toggle, setFocus]);

	return (
		<div className={classnames(className, _checked && 'checked', _focus && 'focus', mouseover && 'mouseover')} onClick={onClick} {...props} />
	);
})`
	position: relative;	
	cursor: pointer;
	width: 6vmin;
	height: 6vmin;
	transition: all 250ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	background: red;
	opacity: 1;
	
	
	
	@media (max-width: 420px) and (orientation: portrait) {
		width: 2rem;
		height: 2rem;
	}

	
	
	&:before {
		${fullsizeBeforeBlock}
		background: ${colours[3]};
		z-index: 1;
		border: 3px solid ${theme.rgba.primary.darkest(0.9)};
		transition: all 250ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
		box-sizing: border-box;  		  		
	}
	
	&:after {
		${fullsizeBeforeBlock}
		z-index: 2;
		background: url(../illustrations/heart.svg);
		background-size: 60%;
		background-position: center;
		background-repeat: no-repeat;
		opacity: 0;		
		transition: all 250ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	}
	
	&.focus {
		&:after {
		  animation: ${steadyHeartbeat} 2s infinite;			
		}
	}
	
	&:hover, &.mouseover {
		&:before {
			border: 3px solid ${theme.rgba.primary.darkest(0.6)};
		}
		
		&:after {
		  animation: ${steadyHeartbeat} 0.8s infinite;			
		}		
	}
	
	&:active {
		&:after {
		  animation: ${onePulseHeartbeat} 0.5s;			
			transform: scale(1.2);
		}
	}
	
	&.checked {
		&:before {
			border: 3px solid ${theme.rgba.primary.darkest(0.2)};
		}
		
		&:after {
		  animation: ${settleHeartbeat} 1.2s;
			opacity: 1;
		}
		
		&:active, &:hover, &.mouseover {
			&:after {
				animation: ${settleHeartbeat} 1.2s infinite;				
			}			
		}
	}
`;


const Wrapper = styled.div`
	// padding-bottom: 4rem;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: row; 	
	
			
	&:before {
		content: "";
		position: absolute;
		top: 0; 
		left: 0;
		width: 100%; 
		height: 100%;  		
		opacity: .5; 
		z-index: 0;
	
			// background: url(../img/hikers.jpeg);
			// background-repeat: no-repeat;
			// background-size: cover;
			// background-repeat: no-repeat;
			// background-position: bottom left;		 
			// background-attachment: fixed;		 		
	}
	

	@media (max-width: 960px) {
		&:before {
			background-size: auto 100%;
		}
	}
	
	
	h2, h3 {
		margin-bottom: 0;
		font-size: 2vmin;
	}
	
	@media (max-width: 420px) and (orientation: portrait) {
		h2, h3 {
			font-size: 1.1rem;
		}
	}
	
`;

const Logo = styled(LogoText)`
	font-size: 9.5vmin;

	color: ${theme.hex.primary.darkest};
	text-shadow: 1px 1px 3px ${theme.rgba.primary.darkest(0.5)};
	
	@media (max-width: 420px) and (orientation: portrait) {
		font-size: 3rem;
	}
	
`;


const Hidden = styled.div`
	display: none;
`;