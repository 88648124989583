import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import Navbar from './Header/Navbar';
import Hamburger from './Header/Hamburger';
import Sidebar from './Header/Sidebar';

const SidebarContext = React.createContext();

export function SidebarState({children}) {
	const state = useState(false);
	return (
		<SidebarContext.Provider value={state}>
			{children}
		</SidebarContext.Provider>
	);
}

export default function Header() {
	const [sidebar, toggle] = useContext(SidebarContext);
	return (
		<Wrapper>
			<Overlay sidebar={sidebar} onClick={() => toggle(!sidebar)} />
			<Navbar />
			<Hamburger sidebar={sidebar} toggle={toggle} />
			<Sidebar sidebar={sidebar} toggle={toggle} />
		</Wrapper>
	);
}

export function useSidebarToggle() {
	return useContext(SidebarContext)[1];
}


export const Wrapper = styled.div`
	background: transparent;
	top: 0;
	right: 0;
	position: fixed;	
	z-index: 10 !important;
	
	${({sidebar}) =>
		!sidebar && `
			@media (min-width: 961px) {
				display: none;
			}
		`}
	
`;

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: none;
	transition: 0.4s;
	background: rgba(0, 0, 0, 0.7);

	${({sidebar}) =>
		sidebar &&
	`
				display: block;
				z-index: 8;	
		`}
`;
