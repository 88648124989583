import React from 'react';
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "./Hero";

export default function Index() {
	return (
		<Layout>
			<SEO />
			<Hero />
		</Layout>
	);
}