import React from 'react';
import styled from 'styled-components';
import NavLinks from './NavLinks';
import LogoText from "../../common/LogoText";
import {theme} from "../../../core/theme";
import {materialShadows} from "../../../core/styles";


export default function Sidebar({sidebar, toggle}) {
	return (
		<Wrapper active={sidebar} onClick={toggle}>
			<Logo Component='p' />
			<Nav />
		</Wrapper>
	);
}




export const Wrapper = styled.div`
	position: fixed;
	z-index: 10;
	overflow: auto;
	top: 0px;
	right: -275px;
	width: 0;
	opacity: 0;
	height: 100%;
	background-color: #fff;
	transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	${materialShadows[5]}

	${({ active }) =>
		active &&
		`
				width: 20%;
				right: 0px;
				opacity: 1;
	
				@media (max-width: 960px) {
					width: 40%;
				}
	
				@media (max-width: 600px) {
					width: 75%;
				}
		`}
		
	.logo {
		
	}
`;

const Logo = styled(LogoText)`
	color: ${theme.hex.tertiary.darkest};
	padding: 1.15rem 1rem 1rem 1rem;
	padding-left: 3rem;
	font-size: 1rem;
	margin: 0;
	
	@media (min-width: 601px) {
		font-size: 3.2vw;
	}
	
`;

const Nav = styled(NavLinks)`
	padding: 1rem 3rem 3rem 3rem;
	display: flex;
	flex-direction: column;

	a {
			margin-bottom: 1rem;

			&:last-child {
					margin-bottom: unset;
			}					
	}
	
	@media (max-width: 520px) {
		.hireme {	
			padding: 0.7rem 1.4rem;
		}
	}
`;