import isFunction from 'lodash.isfunction';

export function safeClick(fn) {
	return function (e) {
		e.preventDefault();
		e.stopPropagation();
		e.nativeEvent.preventDefault();
		e.nativeEvent.stopImmediatePropagation();

		if (isFunction(fn)) {
			fn(e);
		}
	};
}