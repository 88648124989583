import React, {useEffect} from 'react';
import {ErrorMessage, FastField, Form, withFormik} from 'formik';
import Recaptcha from 'react-google-recaptcha';
import * as Yup from 'yup';
import styled from 'styled-components';
import {forms, recaptcha_key} from '../../../data/config';
import Button from '../common/Button';
import Input from '../common/Input';
import {materialShadowHover, materialShadows} from "../../core/styles";
import {theme} from "../../core/theme";

function ContactForm({className, setFieldValue, isSubmitting, values, errors, touched, onAction}) {
	const {success} = values;

	useEffect(() => {
		let t;
		if (success) {
			t = setTimeout(() => {
				onAction();
			}, 2000);
		}
		return () => clearTimeout(t);
	}, [success, onAction]);

	return (
		<Form
			className={className}
			name={forms.alpha}
			method="post"
			data-netlify="true"
			data-netlify-recaptcha="true"
			data-netlify-honeypot="bot-field"
		>
			<InputField>
				<Input
					id="email"
					aria-label="email"
					component="input"
					as={FastField}
					type="email"
					name="email"
					placeholder="Email*"
					error={touched.email && errors.email}
					autoFocus
				/>
				<ErrorMessage component={Error} name="email" />
			</InputField>
			{values.email && (
				<InputField>
					<FastField
						component={Recaptcha}
						sitekey={recaptcha_key}
						name="recaptcha"
						onChange={value => setFieldValue('recaptcha', value)}
					/>
					<ErrorMessage component={Error} name="recaptcha" />
				</InputField>
			)}
			{values.success && (
				<InputField>
					<Center className='successMessage'>
						<h4>Your message has been successfully sent.</h4>
						<p>You will receive an email with more details shortly.</p>
					</Center>
				</InputField>
			)}
			<Center>
				<Button tertiary type="submit" disabled={isSubmitting}>
					Say Hello!
				</Button>
				<Button onClick={onAction} color="primary">
					Cancel
				</Button>
			</Center>
		</Form>
	);
}

export default withFormik({
	mapPropsToValues: () => ({
		email: '',
		recaptcha: '',
		success: false,
	}),
	validationSchema: () =>
		Yup.object().shape({
			email: Yup.string()
				.email('Invalid email')
				.required('Email field is required'),
			recaptcha: Yup.string().required('Robots are not welcome yet!'),
		}),
	handleSubmit: async (
		{email, recaptcha},
		{setSubmitting, resetForm, setFieldValue}
	) => {
		try {
			const encode = data => {
				return Object.keys(data)
					.map(
						key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
					)
					.join('&');
			};
			await fetch('/?no-cache=1', {
				method: 'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				body: encode({
					'form-name': forms.alpha,
					email,
					'g-recaptcha-response': recaptcha,
				}),
			});
			await setSubmitting(false);
			await setFieldValue('success', true);
			setTimeout(() => resetForm(), 10000);
		} catch (err) {
			setSubmitting(false);
			setFieldValue('success', false);
			alert('Something went wrong, please try again!') // eslint-disable-line
		}
	},
})(styled(ContactForm)`
	
	button {
		display: inline-block;
		margin-right: 1rem;
		margin-bottom: 1rem;
	}
	
	h4 {
		margin-bottom: 0.33rem;
		color: ${theme.hex.tertiary.main};	
	}
	
	.successMessage {
	}
`);


const Error = styled.span`
	color: #ff4136;
`;

const Center = styled.div`
	text-align: left;

	h4 {
		font-weight: normal;
	}
	
`;

const InputField = styled.div`
	position: relative;
	margin-bottom: 1rem;
	
	input, textarea {
		${materialShadows[1]}
		border-width: 0;
		border-left-width: 1px;
	}
	
	input:focus, textarea:focus {
		${materialShadowHover[1]}
		border-left-width: 3px;
	}
	
	@media (max-width: 960px) {
		width: 100%; 
	}
	@media (max-width: 420px) {
		width: 100%; 
	}
`;

