module.exports = {
	defaultTitle: 'DoBuddy',
	logo: 'https://dobuddy.app/dobuddy_logo.png',
	author: 'BuiltByBrayne',
	url: 'https://dobuddy.app',
	legalName: 'DoBuddy',
	defaultDescription: 'The todo list with your wellbeing at heart',
	socialLinks: {
		// twitter: 'http://www.twitter.com/smakosh',
		// github: 'https://github.com/perchten',
		// linkedin: 'https://www.linkedin.com/in/albrayne/',
		// instagram: 'https://instagram.com/smakosh19',
		// youtube: 'https://www.youtube.com/user/smakoshthegamer',
		// google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
	},
	googleAnalyticsID: 'UA-149148471-1',
	themeColor: '#0842D4',
	backgroundColor: '#678AE0',
	social: {
		// facebook: 'appId',
		// twitter: '@smakosh',
	},
	address: {
		city: 'Bristol',
		region: 'Region',
		country: 'UK',
		zipCode: 'BS5 6DG',
	},
	contact: {
		email: 'al@dobuddy.app',
		phone: '+44(0)77779266625',
	},
	foundingDate: '2014',
	recaptcha_key: '6LeAhr4UAAAAAMsPahFz0DsEJb2Kesnyv0nMYUZ_',
	forms: {
		alpha: "dobuddy-alpha-signup-form"
	}
};
