import React from 'react';
import styled from 'styled-components';
import AnchorLink from "./AnchorLink";
import Button from "./Button";
import {theme} from "../../core/theme";


const HireMe = styled(({className}) => {
	return (
		<Button secondary className={`hireme ${className}`}>
			<AnchorLink href="#contact">Hire Me</AnchorLink>
		</Button>
	);
})`
	color: ${theme.hex.tertiary.darker};
	cursor: pointer;
	text-shadow: none;
	
	a {
		text-shadow: none !important;
	}
	
	&:hover, &:active, &:focus  {
		color: ${theme.hex.secondary.darkest};
	}	
`;

export default HireMe;