import React from 'react';
import styled from 'styled-components';
import AnchorLink from "../../common/AnchorLink";
import HireMe from "../../common/hireme";

export default function NavLinks({className}) {
	return (
		<Wrapper className={className}>
			<AnchorLink href="#motivation">Motivation</AnchorLink>
			<AnchorLink href="#projects">Projects</AnchorLink>
			<AnchorLink href="#experience">Experience</AnchorLink>
			<HireMe />
		</Wrapper>
	);
}


export const Wrapper = styled.div`	
	a {
		cursor: pointer;
		color: #6d6d6d;
		text-decoration: none;		
	}
`;
