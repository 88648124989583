import styled from 'styled-components';

const Container = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	width: 90%;	

	@media (min-width: 601px) {
		width: 90%;
	}

	@media (min-width: 993px) {
		width: 80%;
	}
	
	@media (max-width: 420px) {
		width: 98%;
	}

	
	${({fullWidth}) =>
		fullWidth && `
		@media (max-width: 960px) {
			width: 100%;
			max-width: 100%;
		}
	`}
`;

export default Container;