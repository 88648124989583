import React from 'react';
import styled from 'styled-components';
import NavLinks from './NavLinks';
import Container from "../../common/Container";
import {theme} from "../../../core/theme";

export default function Navbar() {
	return (
		<Wrapper as={Container}>
			<Nav />
		</Wrapper>
	);
};


const Wrapper = styled.div`
	padding: 1.5rem 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	a {
		color: ${theme.hex.secondary.lightest};
		cursor: pointer;
	}
`;

const Nav = styled(NavLinks)`
	@media (max-width: 960px) {
			display: none;
	}

	a {
			margin-right: 1rem;

			&:last-child {
					margin-right: unset;
			}
	}
`;



// const LogoBlock = styled.div`
// 	display: flex;
// 	align-items: center;
// 	color: ${theme.hex.primary.darker};
// `;
//
// const Title = styled.div`
// `;
//
//
// const Thumbnail = styled.div`
//
// 	@media (max-width: 960px) {
// 		width: 100%;
// 	}
//
// 	img {
// 		height: 40px;
// 		margin: 0 0.66rem 0 0;
// 	}
// `;