import styled from 'styled-components';
import React, {useCallback, useEffect, useState} from "react";
import useIsInViewport from "use-is-in-viewport";
import ParticleEffectButton from "react-particle-effect-button";
import {useSmoothScroll, useToggleState} from "../../core/hooks";
import {theme} from "../../core/theme";
import {svgStripes} from "../../core/styles";
import {useSidebarToggle} from "../theme/Header";


export default function Button({particles, ...props}) {
	if (particles) {
		return <ParticleButton {...props} />;
	}
	return <StyledButton {...props} />;

}

function ParticleButton({anchor, onClick, children, ...props}) {
	const {secondary, tertiary} = props;
	const [hidden, toggleHidden] = useToggleState(false);
	const [animating, setAnimating] = useState(false);
	const smoothScroll = useSmoothScroll(anchor);
	const [isInViewport, childElToWatch] = useIsInViewport();


	const animate = useCallback(() => {
		if (animating) {
			return;
		}
		setAnimating(true);
		toggleHidden();
	}, [toggleHidden, animating, setAnimating]);

	const _onClick = useCallback((e) => {
		animate();
		if (onClick) {
			onClick(e);
		}
	}, [animate, onClick]);

	const onBegin = useCallback(() => {
		if (anchor) {
			smoothScroll();
		}
	}, [smoothScroll, anchor]);

	const onComplete = useCallback(() => {
		setAnimating(false);
	}, [setAnimating]);

	useEffect(() => {
		if (!isInViewport && hidden && !animating) {
			animate();
		}
	}, [isInViewport, hidden, animating, setAnimating, toggleHidden, animate]);

	return (
		<ParticleEffectButton
			hidden={hidden}
			onBegin={onBegin}
			onComplete={onComplete}
			color={secondary ? theme.hex.secondary.main : (tertiary ? theme.hex.tertiary.main : theme.hex.primary.main)}
			duration={500}
			easing='easeOutQuad'
			speed={0.1}
			particlesAmountCoefficient={10}
			oscillationCoefficient={80}
			type='circle'
			style='fill'
			direction='left'
			canvasPadding={150}
		>
			<StyledButton {...props} onClick={_onClick} ref={childElToWatch}>
				{children}
			</StyledButton>
		</ParticleEffectButton>
	);
}




const StyledButton = styled.button`
	cursor: pointer;
	border-radius: 3px;
	padding: 0.7rem 2.5rem;
	border: none;
	-webkit-appearance: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: ${theme.hex.primary.contrastText};
	background: ${theme.hex.primary.main} url(${svgStripes});
	
	${({secondary}) =>
		secondary && `
		background: ${theme.hex.secondary.main} url(${svgStripes});
		color: ${theme.hex.secondary.contrastText}
	`}
	
	${({tertiary}) =>
		tertiary && `
		background: ${theme.hex.tertiary.main} url(${svgStripes});
		color: ${theme.hex.tertiary.contrastText}
	`}
	
	
	
	&:focus {
		outline: none;
	}
	
	&:hover, &:active, &:focus  {
		background: ${theme.hex.primary.light} url(${svgStripes});
		
		${({secondary}) =>
		secondary && `
			background: ${theme.hex.secondary.light} url(${svgStripes});					
		`}
		
		${({tertiary}) =>
		tertiary && `	
			background: ${theme.hex.tertiary.light} url(${svgStripes});
		`}
	}

	&:disabled {
		background: gray;
	}
	
	${({shadow}) =>
		shadow && `
			text-shadow: 1px 1px 3px ${theme.rgba.primary.darkest(0.5)};	
		`}

`;