import React from 'react';
import classnames from 'classnames';


export default function LogoText({Component, className, ...props}) {
	if (Component) {
		return <Component className={classnames("logofont", className)}>DoBuddy</Component>;
	}

	return <span className={classnames("logofont", className)} {...props}>DoBuddy</span>;
}