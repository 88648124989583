import _debug from "debug";

export function getDebug(ref = "__NO_REF__") {
	// const i = ref.indexOf('/monitoring-ui/');
	// if (i >= 0) {
	//   ref = ref.slice(i + 15);
	// }
	const dbg = _debug(ref);
	if (dbg.enabled) {
		dbg.group = (name, ...args) => console.groupCollapsed(`${name} %c (${ref})`, ...args, 'color: #bbb');
		dbg.groupError = (name) => console.group(`%c ${name}`, 'color: red');
		dbg.groupLog = (...args) => console.log(...args);
		dbg.groupEnd = () => console.groupEnd();
	} else {
		dbg.group = () => {
		};
		dbg.groupError = () => {
		};
		dbg.groupLog = () => {
		};
		dbg.groupEnd = () => {
		};
	}

	return dbg;
}