import React from 'react';
import {Global} from '../../core/styles';
import '../../core/fonts.css';

export default function Layout({children}) {
	return (
		<React.Fragment>
			<Global />
			{children}
		</React.Fragment>
	);
}