import React, {useCallback} from 'react';
import {useSmoothScroll} from "../../core/hooks";
import {safeClick} from "../../core/utils";
import {useSidebarToggle} from "../theme/Header";


export default function AnchorLink({offset, id, href, onClick, ...props}) {
	const toggleSidebar = useSidebarToggle();
	const _smoothScroll = useSmoothScroll(id || href.slice(1), offset);
	const smoothScroll = useCallback(safeClick(e => {
		_smoothScroll();
		toggleSidebar(false);
		if (onClick) {
			onClick(e);
		}
	}), [_smoothScroll, onClick]);

	return <a {...props} onClick={smoothScroll} />;
}
